/* eslint-disable no-undef */
/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative TimonClick={(e) => e.preventDefault()}

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import axios from "axios";
import SimpleFooter from "common/components/Footers/SimpleFooter";
import PasswordInput from "modules/components/password-input.component";
import React, { useState } from "react";
// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Row,
} from "reactstrap";
import "./register.css";

export default function RegisterPage() {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [company, setCompany] = useState("");
  const [error, setError] = useState("");
  const [policy, setPolicy] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  // helper function to handle form submission
  // eslint-disable-next-line no-undef
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError("");
    // perform basic input validation
    if (
      !username ||
      !password ||
      !email ||
      !firstName ||
      !lastName ||
      !company
    ) {
      setError("All form fields are required");
      return;
    }

    if (policy === false) {
      setError(
        "In order to register you have to read and agree on Privacy Policy",
      );
      return;
    }

    // create object to be sent to the server
    const data = { username, password, email, firstName, lastName, company };

    try {
      // use Axios to send a POST request to the server
      // with the user data as the request body
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}auth/local/register`,
        data,
      );

      if (response) {
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
          window.location.replace("/login");
        }, 5000);
      }

      resetForm();
    } catch (error) {
      setError(error.response.data.error.message);
    }
  };

  const resetForm = () => {
    setUsername("");
    setEmail("");
    setFirstName("");
    setLastName("");
    setPassword("");
    setCompany("");
    setPolicy(false);
  };

  return (
    <>
      <main>
        <section className="section section-shaped section-lg">
          <div className="shape shape-style-1 bg-gradient-default">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
          <Container className="pt-lg-2">
            <Row className="justify-content-center">
              <Col lg="7">
                <Card className="bg-secondary shadow border-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      <h3>Register</h3>
                    </div>
                    {showAlert && (
                      <Alert color="success">
                        Congratulations, you have successfully signed up! Please
                        note that your account must be validated by an
                        administrator before you can log in. We appreciate your
                        patience and will notify you as soon as your account has
                        been validated. Thank you for choosing our service.
                      </Alert>
                    )}
                    {error && <p className="error">{error}</p>}
                    <Form
                      role="form"
                      onSubmit={handleSubmit}
                      id="register-form"
                    >
                      <FormGroup>
                        <InputGroup className="input-group-alternative mb-3">
                          <InputGroupText>
                            <i className="ni ni-hat-3" />
                          </InputGroupText>

                          <Input
                            placeholder="Username"
                            id="username"
                            value={username}
                            onChange={(event) => {
                              setUsername(event.target.value);
                            }}
                            type="text"
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <InputGroup className="input-group-alternative mb-3">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                          <Input
                            placeholder="Email"
                            id="email"
                            value={email}
                            onChange={(event) => {
                              setEmail(event.target.value);
                            }}
                            type="email"
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <InputGroup className="input-group-alternative mb-3">
                          <InputGroupText>
                            <i className="ni ni-single-02" />
                          </InputGroupText>

                          <Input
                            placeholder="First name"
                            id="firstName"
                            value={firstName}
                            onChange={(event) => {
                              setFirstName(event.target.value);
                            }}
                            type="text"
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <InputGroup className="input-group-alternative mb-3">
                          <InputGroupText>
                            <i className="ni ni-single-02" />
                          </InputGroupText>

                          <Input
                            placeholder="Last name"
                            id="lastName"
                            value={lastName}
                            onChange={(event) => {
                              setLastName(event.target.value);
                            }}
                            type="text"
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup>
                        <InputGroup className="input-group-alternative mb-3">
                          <InputGroupText>
                            <i className="ni ni-shop" />
                          </InputGroupText>

                          <Input
                            placeholder="Company"
                            type="text"
                            id="company"
                            value={company}
                            onChange={(event) => setCompany(event.target.value)}
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup>
                        <PasswordInput
                          value={password}
                          onChange={(event: {
                            target: { value: React.SetStateAction<string> };
                          }) => setPassword(event.target.value)}
                        />
                      </FormGroup>

                      <Row className="my-4">
                        <Col xs="12">
                          <div className="custom-control custom-control-alternative custom-checkbox">
                            <input
                              className="custom-control-input"
                              id="customCheckRegister"
                              type="checkbox"
                              checked={policy}
                              onChange={() => {
                                setPolicy(!policy);
                              }}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheckRegister"
                            >
                              <span>
                                I agree with the{" "}
                                <a
                                  // onClick={(e) => e.preventDefault()}
                                  href="https://www.pannovate.com/"
                                  target="_blank"
                                >
                                  Privacy Policy
                                </a>
                              </span>
                            </label>
                          </div>
                        </Col>
                      </Row>
                      <div className="text-center">
                        <Button type="submit" className="mt-4" color="primary">
                          Create account
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
        <SimpleFooter />
      </main>
    </>
  );
}
