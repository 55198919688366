/* eslint-disable no-undef */
import axios from "axios";
import PublicLayout from "common/components/layouts/public.layout";
import AppContext from "context/AppContext";
import ContentRichTextSection from "modules/components/content-rich-text-section.component";
import ContentSection from "modules/components/content-section.component";
import HeroSection from "modules/components/hero-section.component";
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { Spinner } from "reactstrap";

export const GlobalPageLayout: React.FC<PropsWithChildren<any>> = (
  _props: PropsWithChildren<any>,
) => {
  const { data, setAppData } = useContext(AppContext);
  const [loading, setLoading] = useState<boolean>(false);
  window.scrollTo(0, 0);

  const getPages = async () => {
    // eslint-disable-next-line no-undef
    const token = localStorage.getItem("auth_token");
    setLoading(true);
    try {
      let response: any = {};
      if (token) {
        const options = {
          headers: {
            "content-type": "application/json",
            // eslint-disable-next-line no-undef
            Authorization: "Bearer " + token,
          },
        };
        response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}pages${_props.location.pathname}`,
          options,
        );
      } else {
        response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}pages${_props.location.pathname}`,
        );
      }

      setAppData({
        page: response.data,
      });
      setLoading(false);
    } catch (error) {
      window.location.replace("/");
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getPages();
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }, [_props.location.pathname]);

  if (loading) {
    return (
      <div
        className="w-100 h-100 d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh" }}
      >
        <Spinner color="primary" />
      </div>
    );
  }

  return (
    <PublicLayout page={data.page}>
      <div className="position-relative">
        {data.page?.sections.map((item: any, index: number) => {
          if (item.__component === "section.hero") {
            return <HeroSection item={item} key={index} />;
          } else if (item.__component === "section.content") {
            return <ContentSection item={item} key={index} />;
          } else if (item.__component === "content.rich-text") {
            return <ContentRichTextSection item={item} key={index} />;
          }
        })}
      </div>
    </PublicLayout>
  );
};
