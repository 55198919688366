/* eslint-disable no-undef */
import Logo from "assets/img/brand/syllo-logo.png";
import AppContext from "context/AppContext";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  Row,
  UncontrolledCollapse,
  UncontrolledDropdown,
} from "reactstrap";
import "./MainNavbar.css";

export default function MainNavbar() {
  const [menuItems, setMenuItems] = useState([]);
  // eslint-disable-next-line no-undef
  const token = localStorage.getItem("auth_token");
  // eslint-disable-next-line no-undef
  const username = localStorage.getItem("auth_username");
  const { data } = useContext(AppContext);

  const handleLogout = () => {
    // eslint-disable-next-line no-undef
    localStorage.clear();
    // eslint-disable-next-line no-undef
    window.location.replace("/");
  };

  const loadMenu = async () => {
    if (!data.page?.menu.menu.Item) {
      return [];
    }
    return data.page?.menu.menu.Item;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const menuData = await loadMenu();
        setMenuItems(menuData);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }, [data]);

  useEffect(() => {
    if (menuItems && menuItems.length === 0) {
      setMenuItems(JSON.parse(localStorage.getItem("menu")));
    }
  }, [menuItems]);

  return (
    <header className="header-global">
      <Navbar
        className="navbar-main navbar-transparent navbar-light headroom"
        expand="lg"
        id="navbar-main"
      >
        <Container>
          <Row className="align-items-center justify-content-between">
            <NavbarBrand
              className="mr-lg-5 align-items-center"
              to="/"
              tag={Link}
            >
              <img alt="logo" src={Logo} />
            </NavbarBrand>

            <button className="navbar-toggler" id="navbar_global">
              <span className="navbar-toggler-icon" />
            </button>
            <UncontrolledCollapse toggler="#navbar_global" navbar>
              <div className="navbar-collapse-header">
                <Row>
                  <Col className="collapse-brand" xs="6">
                    <Link to="/">
                      <img alt="logo-brand" src={Logo} />
                    </Link>
                  </Col>
                  <Col className="collapse-close" xs="6">
                    <button className="navbar-toggler" id="navbar_global">
                      <span />
                      <span />
                    </button>
                  </Col>
                </Row>
              </div>
              {menuItems ? (
                <Nav className="navbar-nav-hover align-items-lg-center" navbar>
                  <NavItem></NavItem>

                  {menuItems && (
                    <UncontrolledDropdown nav>
                      <DropdownToggle nav>
                        <i className="ni ni-collection d-lg-none mr-1" />
                        <span className="nav-link-inner--text">
                          Pages <i className="fa fa-caret-down mr-2" />
                        </span>
                      </DropdownToggle>
                      <DropdownMenu>
                        {menuItems.map((page) =>
                          page && page.menu_item ? (
                            <DropdownItem key={page.id}>
                              <Link
                                to={
                                  page.menu_item.URL
                                    ? page.menu_item.URL
                                    : page.menu_item.Page.Slug
                                }
                              >
                                {page.menu_item.Title}
                              </Link>
                            </DropdownItem>
                          ) : null,
                        )}
                        {token && (
                          <DropdownItem>
                            <Link to="/documentation">
                              <span className="nav-link-inner--text font-weight-bold">
                                API Documentation
                              </span>
                            </Link>
                          </DropdownItem>
                        )}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  )}
                </Nav>
              ) : (
                <Link to="/" className="font-weight-bold">
                  Home
                </Link>
              )}
              <Nav className="align-items-lg-center ml-lg-auto" navbar>
                <NavItem className="d-block ml-lg-4 mb-4 mb-lg-0">
                  {!token && (
                    <Link to="/register">
                      <Button
                        color="info"
                        type="button"
                        style={{ minWidth: 140 }}
                      >
                        <span className="btn-inner--icon">
                          <i className="fa fa-user-plus mr-2" />
                        </span>
                        <span className="nav-link-inner--text ml-1">
                          Register
                        </span>
                      </Button>
                    </Link>
                  )}
                </NavItem>
                <NavItem className="d-block ml-lg-4">
                  {!token && (
                    <Link to="/login">
                      <Button
                        color="secondary"
                        type="button"
                        style={{ minWidth: 140 }}
                      >
                        <span className="btn-inner--icon">
                          <i className="fa fa-sign-in  mr-2" />
                        </span>
                        <span className="nav-link-inner--text ml-1">
                          Sign In
                        </span>
                      </Button>
                    </Link>
                  )}
                </NavItem>
                {token && (
                  <UncontrolledDropdown className="profile-menu" group>
                    <DropdownToggle nav caret>
                      {username} <i className="fa fa-user-circle-o mr-2" />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={handleLogout}>
                        <span className="btn-inner--icon">
                          <i className="fa fa-sign-out  mr-2" />
                        </span>
                        <span className="nav-link-inner--text ml-1 font-weight-bold">
                          Sign Out
                        </span>
                      </DropdownItem>
                      <DropdownItem>
                        <Link to="/change-password">
                          <span className="btn-inner--icon">
                            <i className="ni ni-lock-circle-open mr-2" />
                          </span>
                          <span className="nav-link-inner--text ml-1 font-weight-bold">
                            Change password
                          </span>
                        </Link>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )}
              </Nav>
            </UncontrolledCollapse>
          </Row>
        </Container>
      </Navbar>
    </header>
  );
}
