/* eslint-disable no-undef */
import axios from "axios";
import { PrivateLayout } from "common/components/layouts/private.layout";
import Sidebar from "common/components/Sidebar/Sidebar";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { Container, Spinner } from "reactstrap";
import "./documentPage.css";
import { SwaggerPage } from "./swagger.page";

export const DocumentDetailsPage: React.FC<PropsWithChildren<any>> = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [bundles, setBundles] = useState<any>([]);
  const [activeBundle, setActiveBundle] = useState<any>(null);
  const [activePage, setActivePage] = useState<any>(null);

  const queryParameters = new URLSearchParams(window.location.search);
  const selectedBundleId = queryParameters.get("bundle");

  const getPageAndBundles = async () => {
    const token = localStorage.getItem("auth_token");

    try {
      const options = {
        headers: {
          "content-type": "application/json",
          // eslint-disable-next-line no-undef
          Authorization: "Bearer " + token,
        },
      };

      const responseBundles = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}doc-bundles`,
        options,
      );

      if (responseBundles.data) {
        setBundles(
          responseBundles.data.filter(
            (x: { is_public: boolean }) => !x.is_public,
          ),
        );
      }

      const responseActivePage = await axios.get(
        `${
          process.env.REACT_APP_SERVER_URL
        }bundle/${selectedBundleId}/page${window.location.pathname.replace(
          "/documentation",
          "",
        )}`,
        options,
      );

      if (responseActivePage.data) {
        localStorage.setItem(
          "active_bundle_id",
          responseActivePage.data.selected_bundle,
        );
        setActiveBundle(
          responseActivePage.data.bundles.find(
            (x: any) =>
              x.id === Number(responseActivePage.data.selected_bundle),
          ),
        );
        setActivePage(responseActivePage.data);
      }
    } catch (error) {
      window.location.replace("/documentation");
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getPageAndBundles();
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }, [window.location.pathname]);

  if (bundles && bundles.length === 0) {
    return (
      <div
        className="w-100 h-100 d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh" }}
      >
        <Spinner color="primary" />
      </div>
    );
  }

  return (
    <PrivateLayout
      setShowSidebar={setShowSidebar}
      showSidebar={showSidebar}
      bundles={bundles}
      getActiveBundle={(item: any) => setActiveBundle(item)}
      selectedBundle={activeBundle}
    >
      <Container fluid="true">
        <Sidebar showSidebar={showSidebar} activeBundle={activeBundle} />
        <SwaggerPage page={activePage} />
      </Container>
    </PrivateLayout>
  );
};
