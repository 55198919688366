/* eslint-disable no-undef */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
} from "reactstrap";
import Logo from "../../../assets/img/brand/syllo-logo.png";

export default function PrivateNavbar({
  showSidebar,
  setShowSidebar,
  bundles,
  getActiveBundle,
  selectedBundle,
}) {
  const queryParameters = new URLSearchParams(window.location.search);
  const selectedBundleId = queryParameters.get("bundle");

  const [activeBundleId, setActiveBundleId] = useState(null);
  const [activeBundle, setActiveBundle] = useState(selectedBundle || null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    if (selectedBundleId) {
      setActiveBundleId(selectedBundleId);
    } else if (localStorage.getItem("active_bundle_id")) {
      setActiveBundleId(localStorage.getItem("active_bundle_id"));
    } else setActiveBundleId(bundles[0].id);
  }, []);

  const getActiveBundleFromId = async (bundleId) => {
    try {
      const options = {
        headers: {
          "content-type": "application/json",
          // eslint-disable-next-line no-undef
          Authorization: "Bearer " + localStorage.getItem("auth_token"),
        },
      };
      if (bundleId) {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}doc-bundles/${bundleId}`,
          options,
        );

        if (response.data) {
          setActiveBundle(response.data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getActiveBundleFromId(activeBundleId);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }, [activeBundleId]);

  useEffect(() => {
    if (activeBundle) {
      getActiveBundle(activeBundle);
    }
  }, [activeBundle]);

  const bundleLogo =
    activeBundle?.Logo.url &&
    `${process.env.REACT_APP_SERVER_URL?.replace("/api/", "")}${
      activeBundle?.Logo.url
    }`;

  return (
    <Navbar
      className="navbar-horizontal navbar-dark bg-info"
      style={{ minHeight: 84 }}
      expand="lg"
      container="fluid"
      fixed="top"
    >
      <Container fluid="true">
        <NavbarBrand href="/">
          {activeBundle && <img src={bundleLogo || Logo} />}
        </NavbarBrand>
      </Container>

      {activeBundle && (
        <ul className="flex-row mr-auto ml-4 d-md-flex navbar-nav document-menu">
          <li className="nav-item mr-3">
            <Link className="nav-link" to="/">
              Home
            </Link>
          </li>
          <Nav className="navbar-nav-hover align-items-lg-center" navbar>
            <NavItem></NavItem>
            <Dropdown isOpen={dropdownOpen || false} toggle={toggleDropdown}>
              <DropdownToggle nav>
                <span className="nav-link-inner--text">
                  {activeBundle?.Title || "Bundles"}{" "}
                  <i className="fa fa-caret-down mr-2" />
                </span>
              </DropdownToggle>
              <DropdownMenu>
                {bundles.map((bundle) =>
                  bundle ? (
                    <DropdownItem
                      key={bundle.id}
                      onClick={() => [
                        setActiveBundleId(bundle.id),
                        localStorage.setItem("active_bundle_id", bundle.id),
                        window.location.replace("/documentation"),
                      ]}
                    >
                      <span className="nav-link-inner--text ml-1 font-weight-bold">
                        {bundle.Title}
                      </span>
                    </DropdownItem>
                  ) : null,
                )}
              </DropdownMenu>
            </Dropdown>
          </Nav>
        </ul>
      )}
      <button
        aria-controls="ct-docs-nav"
        aria-expanded="false"
        aria-label="Toggle docs navigation"
        className="navbar-toggler ct-search-docs-toggle d-block d-md-none ml-auto ml-sm-0"
        data-target="#ct-docs-nav"
        data-toggle="collapse"
        id="ct-docs-nav"
        type="button"
        onClick={() => {
          // eslint-disable-next-line no-undef
          window.scrollTo(0, 0);
          setShowSidebar(!showSidebar);
        }}
      >
        <span className="navbar-toggler-icon"></span>
      </button>
    </Navbar>
  );
}
