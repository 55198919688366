import axios from "axios";
import { UnauthenticatedLayout } from "common/components/layouts/unauthenticated.layout";
import React, { useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Row,
} from "reactstrap";

export const ForgotPasswordPage: React.FC<any> = (_props: any) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  // eslint-disable-next-line no-undef
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setError("");
    event.preventDefault();
    if (!email) {
      setError("All form fields are required");
      return;
    }
    const data = { email: email };
    try {
      // with the user data as the request body
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}auth/forgot-password`,
        data,
      );

      if (response) {
        setShowAlert(true);
        setTimeout(() => {
          // eslint-disable-next-line no-undef
          window.location.replace("/login");
        }, 3000);
      }

      resetForm();
    } catch (err) {
      setError(err.response.data.error.message);
    }
  };

  const resetForm = () => {
    setError("");
    setEmail("");
  };

  return (
    <UnauthenticatedLayout>
      <section className="section section-shaped section-lg">
        <div className="shape shape-style-1 bg-gradient-default">
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
        </div>
        <Container className="pt-lg-7">
          <Row className="justify-content-center">
            <Col lg="7">
              {showAlert && (
                <Alert color="success">
                  We've sent you a password reset link via email. Please check
                  email Inbox and Junk.
                </Alert>
              )}
              <Card className="bg-secondary shadow border-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <h3>Forgot password</h3>
                  </div>
                  {error && <p className="error">{error}</p>}
                  <Form role="form" onSubmit={handleSubmit}>
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>

                        <Input
                          placeholder="Email"
                          type="email"
                          id="email"
                          value={email}
                          onChange={(event) => setEmail(event.target.value)}
                        />
                      </InputGroup>
                    </FormGroup>

                    <div className="text-center">
                      <Button className="my-4" color="primary" type="submit">
                        Forgot password
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </UnauthenticatedLayout>
  );
};
