import React, { createContext, useState } from "react";
const AppContext = createContext();
export default AppContext;

export const AppProvider = ({ children }) => {
  const [data, setData] = useState({});

  const setAppData = (data) => {
    setData({
      page: data.page,
    });
  };

  let contextData = {
    data: data,
    setAppData: setAppData,
  };

  return (
    <AppContext.Provider value={contextData}>{children}</AppContext.Provider>
  );
};
