/* eslint-disable no-undef */
import React from "react";
import PrivateNavbar from "../Navbars/PrivateNavbar";

type PrivateLayoutProps = {
  children: React.ReactNode;
  showSidebar: boolean;
  bundles: any;
  selectedBundle?: any;
  setShowSidebar: (show: boolean) => void;
  getActiveBundle: (bundle: any) => void;
};

export const PrivateLayout: React.FC<PrivateLayoutProps> = ({
  children,
  showSidebar,
  bundles,
  setShowSidebar,
  getActiveBundle,
  selectedBundle,
}) => {
  return (
    <>
      <PrivateNavbar
        bundles={bundles}
        showSidebar={showSidebar}
        setShowSidebar={setShowSidebar}
        getActiveBundle={(bundle: any) => getActiveBundle(bundle)}
        selectedBundle={selectedBundle}
      />
      <main>{children}</main>
    </>
  );
};
