/* eslint-disable no-undef */
import axios from "axios";
import { PrivateLayout } from "common/components/layouts/private.layout";
import Sidebar from "common/components/Sidebar/Sidebar";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { Container, Spinner } from "reactstrap";
import "./documentPage.css";

export const DocumentPage: React.FC<PropsWithChildren<any>> = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [bundles, setBundles] = useState<any>([]);
  const [activeBundle, setActiveBundle] = useState<any>(null);

  useEffect(() => {
    if (activeBundle) {
      localStorage.setItem("active_bundle_id", activeBundle.id);
    }
  }, [activeBundle]);

  const getBundles = async () => {
    try {
      const options = {
        headers: {
          "content-type": "application/json",
          // eslint-disable-next-line no-undef
          Authorization: "Bearer " + localStorage.getItem("auth_token"),
        },
      };

      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}doc-bundles`,
        options,
      );

      if (response.data) {
        setBundles(
          response.data.filter((x: { is_public: boolean }) => !x.is_public),
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getBundles();
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }, []);

  if (bundles && bundles.length === 0) {
    return (
      <div
        className="w-100 h-100 d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh" }}
      >
        <Spinner color="primary" />
      </div>
    );
  }

  return (
    <PrivateLayout
      setShowSidebar={setShowSidebar}
      showSidebar={showSidebar}
      bundles={bundles}
      getActiveBundle={(item: any) => setActiveBundle(item)}
    >
      <Container fluid="true">
        <Sidebar showSidebar={showSidebar} activeBundle={activeBundle} />
        <main
          className="flex-xl-nowrap  col-12 col-md-9 col-xl-8"
          id="main-content"
        >
          <h3>Please select one bundle item</h3>
        </main>
      </Container>
    </PrivateLayout>
  );
};
