/* eslint-disable no-undef */
import React, { PropsWithChildren } from "react";
import { RedocStandalone } from "redoc";
import "./documentPage.css";

export const SwaggerPage: React.FC<PropsWithChildren<any>> = ({ page }) => {
  return (
    <main className="flex-xl-nowrap" id="main-content">
      {page &&
        page.sections?.map((item: any) => {
          if (item.__component === "swagger.definition") {
            return item.version.Definition.map((definition: any) => {
              return (
                <RedocStandalone
                  spec={definition}
                  key={item.version.id}
                  options={{
                    // hideDownloadButton: true,
                    nativeScrollbars: true,
                    theme: { colors: { primary: { main: "#8CC43F" } } },
                  }}
                />
              );
            });
          }
        })}
    </main>
  );
};
