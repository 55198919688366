/* eslint-disable no-undef */
import axios from "axios";
import PublicLayout from "common/components/layouts/public.layout";
import AppContext from "context/AppContext";
import ContentRichTextSection from "modules/components/content-rich-text-section.component";
import ContentSection from "modules/components/content-section.component";
import HeroSection from "modules/components/hero-section.component";
import React, { PropsWithChildren, useContext, useEffect } from "react";

export const HomePage: React.FC<PropsWithChildren<any>> = (
  _props: PropsWithChildren<any>,
) => {
  const { data, setAppData } = useContext(AppContext);
  window.scrollTo(0, 0);

  const getPages = async () => {
    // eslint-disable-next-line no-undef
    const token = localStorage.getItem("auth_token");
    try {
      let response: any = {};
      if (token) {
        const options = {
          headers: {
            "content-type": "application/json",
            // eslint-disable-next-line no-undef
            Authorization: "Bearer " + localStorage.getItem("auth_token"),
          },
        };
        response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}page/homepage`,
          options,
        );
      } else {
        response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}page/homepage`,
        );
      }
      setAppData({
        page: response.data,
      });
      localStorage.setItem(
        "menu",
        JSON.stringify(response.data.menu.menu.Item),
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    localStorage.setItem("active_bundle_id", "");
    const fetchData = async () => {
      try {
        await getPages();
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }, []);

  return (
    <PublicLayout page={data.page}>
      <div className="position-relative">
        {data.page?.sections.map((item: any, index: number) => {
          if (item.__component === "section.hero") {
            return <HeroSection item={item} key={index} />;
          } else if (item.__component === "section.content") {
            return <ContentSection item={item} key={index} />;
          } else if (item.__component === "content.rich-text") {
            return <ContentRichTextSection item={item} key={index} />;
          }
        })}
      </div>
    </PublicLayout>
  );
};
