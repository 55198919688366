import React from "react";
import ReactMarkdown from "react-markdown";
import { Col, Container } from "reactstrap";

function ContentRichTextSection({ item }: any) {
  return (
    <section
      className="section section-lg"
      style={{ background: item.BackgroundColor }}
    >
      <Container>
        <Col className="col-grid align-items-center">
          {item.Title && <h1>{item.Title}</h1>}
          {item.Subtitle && <h3 className="my-3">{item.Subtitle}</h3>}
          <ReactMarkdown
            className={
              item.Content?.includes("/uploads")
                ? "img-fluid floating text-center"
                : ""
            }
            transformImageUri={(uri) =>
              uri.startsWith("http")
                ? uri
                : `${process.env.REACT_APP_SERVER_URL?.replace(
                    "/api/",
                    "",
                  )}${uri}`
            }
          >
            {item.Content}
          </ReactMarkdown>
        </Col>
      </Container>
    </section>
  );
}

export default ContentRichTextSection;
