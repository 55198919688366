import React, { useState } from "react";
import { Input, InputGroup, InputGroupText } from "reactstrap";

function PasswordInput({ value, onChange, placeholder = "Password" }: any) {
  const [fieldType, setFieldType] = useState<any>("password");

  const toggleShowPassword = () => {
    setFieldType(fieldType === "password" ? "text" : "password");
  };

  return (
    <InputGroup className="input-group-alternative">
      <InputGroupText>
        <i className="ni ni-lock-circle-open" />
      </InputGroupText>

      <Input
        placeholder={placeholder}
        type={fieldType}
        autoComplete="off"
        id="password"
        value={value}
        onChange={onChange}
      />
      {value.length > 0 && (
        <InputGroupText
          onClick={() => toggleShowPassword()}
          style={{ cursor: "pointer" }}
        >
          {fieldType === "password" ? (
            <i className="fa fa-eye" />
          ) : (
            <i className="fa fa-eye-slash" aria-hidden="true"></i>
          )}
        </InputGroupText>
      )}
    </InputGroup>
  );
}

export default PasswordInput;
