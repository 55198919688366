/* eslint-disable no-undef */
import axios from "axios";
import { DocumentDetailsPage } from "modules/document/document-details.page";
import { DocumentPage } from "modules/document/document.page";
import { ChangePasswordPage } from "modules/forgot-password/change-password.page";
import { GlobalPageLayout } from "modules/global-page-layout/global-page-layout.page";
import React, { useEffect, useState } from "react";
import ProtectedRoute from "./ProtectedRoute";

const ProtectedRoutes = ({ ...rest }: any) => {
  const [routes, setRoutes] = useState<any>([]);

  const getPages = async () => {
    try {
      const options = {
        headers: {
          "content-type": "application/json",
          // eslint-disable-next-line no-undef
          Authorization: "Bearer " + localStorage.getItem("auth_token"),
        },
      };

      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}doc-bundles`,
        options,
      );

      if (response.data) {
        const arrayOfRoutes: any[] = [];
        response.data.map(async (items: any) => {
          items.pages.map((item: any) => {
            arrayOfRoutes.push(item);
          });

          setRoutes(arrayOfRoutes);

          const slugs = await Promise.all(
            arrayOfRoutes.map(async (item) => {
              return item.Slug;
            }),
          );

          const isSwaggerUrl = await Promise.all(
            arrayOfRoutes.map(async (route) => {
              if (route.visibility === "private") {
                return `documentation/${route.Slug}`;
              } else {
                return route.Slug;
              }
            }),
          );

          const allRoute = [
            "change-password",
            "documentation",
            ...slugs,
            ...isSwaggerUrl,
          ];

          if (!allRoute.includes(rest.location.pathname.replace("/", ""))) {
            window.location.replace("/");
          } else {
            return;
          }
        });
      }
    } catch (error) {
      // window.location.replace("/");
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getPages();
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }, []);

  const isRouteFromBackend = () => {
    const slugs = routes.map((item: any) => {
      return item.Slug;
    });

    if (slugs.includes(rest.location.pathname.replace("/", ""))) {
      return true;
    }
    return false;
  };

  return (
    <>
      <ProtectedRoute path="/change-password" exact>
        <ChangePasswordPage />
      </ProtectedRoute>
      <ProtectedRoute path="/documentation" exact>
        <DocumentPage />
      </ProtectedRoute>
      <ProtectedRoute path="/documentation/:pageSlug" exact>
        <DocumentDetailsPage />
      </ProtectedRoute>
      {isRouteFromBackend() && (
        <ProtectedRoute path={routes.Slug} exact>
          <GlobalPageLayout {...rest} />
        </ProtectedRoute>
      )}
    </>
  );
};
export default ProtectedRoutes;
