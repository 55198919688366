import MainNavbar from "common/components/Navbars/MainNavbar";
import React from "react";
import { Spinner } from "reactstrap";
import SimpleFooter from "../Footers/SimpleFooter";

const PublicLayout = ({ children, page }: any) => {
  if (!page || page === undefined) {
    return (
      <div
        className="w-100 h-100 d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh" }}
      >
        <Spinner color="primary" />
      </div>
    );
  }

  return (
    <>
      <MainNavbar />
      <main>{children}</main>
      <SimpleFooter />
    </>
  );
};

export default PublicLayout;
