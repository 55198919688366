/* eslint-disable no-undef */
/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import "assets/scss/argon-design-system-react.scss?v1.1.0";
import "assets/scss/overrides.scss";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/vendor/nucleo/css/nucleo.css";
import ProtectedRoutes from "common/navigation/ProtectedRoutes";
import PublicRoutes from "common/navigation/PublicRoutes";
import { HomePage } from "modules/home/home.page";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { AppProvider } from "./context/AppContext";

ReactDOM.render(
  <AppProvider>
    <BrowserRouter>
      <Switch>
        <Route path="/" exact render={(props) => <HomePage {...props} />} />
        {localStorage.getItem("auth_token") ? (
          <ProtectedRoutes />
        ) : (
          <PublicRoutes />
        )}

        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  </AppProvider>,
  // eslint-disable-next-line no-undef
  document.getElementById("root"),
);
