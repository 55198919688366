/* eslint-disable no-undef */
import axios from "axios";
import { UnauthenticatedLayout } from "common/components/layouts/unauthenticated.layout";
import PasswordInput from "modules/components/password-input.component";
import React, { useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
} from "reactstrap";

export const ResetPasswordPage: React.FC<any> = (_props: any) => {
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [error, setError] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const queryParameters = new URLSearchParams(window.location.search);
  const code = queryParameters.get("code");

  // eslint-disable-next-line no-undef
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setError("");
    event.preventDefault();
    if (!password || !passwordConfirmation) {
      setError("All form fields are required");
      return;
    }
    const data = {
      password: password,
      passwordConfirmation: passwordConfirmation,
      code: code,
    };
    try {
      // with the user data as the request body
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}auth/reset-password`,
        data,
      );

      if (response) {
        setShowAlert(true);
        setTimeout(() => {
          // eslint-disable-next-line no-undef
          window.location.replace("/login");
        }, 3000);
      }

      resetForm();
    } catch (err) {
      setError(err.response.data.error.message);
    }
  };

  const resetForm = () => {
    setError("");
    setPassword("");
    setPasswordConfirmation("");
  };

  return (
    <UnauthenticatedLayout>
      <section className="section section-shaped section-lg">
        <div className="shape shape-style-1 bg-gradient-default">
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
        </div>
        <Container className="pt-lg-7">
          <Row className="justify-content-center">
            <Col lg="7">
              {showAlert && (
                <Alert color="success">
                  You have successfully changed your password. Please login
                  again.
                </Alert>
              )}
              <Card className="bg-secondary shadow border-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <h3>Reset password</h3>
                  </div>
                  {error && <p className="error">{error}</p>}
                  <Form role="form" onSubmit={handleSubmit}>
                    <FormGroup>
                      <PasswordInput
                        value={password}
                        onChange={(event: {
                          target: { value: React.SetStateAction<string> };
                        }) => setPassword(event.target.value)}
                      />
                    </FormGroup>

                    <FormGroup>
                      <PasswordInput
                        placeholder="Password confirmation"
                        value={passwordConfirmation}
                        onChange={(event: {
                          target: { value: React.SetStateAction<string> };
                        }) => setPasswordConfirmation(event.target.value)}
                      />
                    </FormGroup>

                    <div className="text-center">
                      <Button className="my-4" color="primary" type="submit">
                        Submit new password
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </UnauthenticatedLayout>
  );
};
