import React from "react";
import ReactMarkdown from "react-markdown";
import { Col, Container, Row } from "reactstrap";

function ContentSection({ item }: any) {
  return (
    <section
      className="section section-lg"
      style={{ background: item.BackgroundColor }}
    >
      <Container>
        {item.Title && <h1>{item.Title}</h1>}
        {item.Subtitle && <h3 className="my-3">{item.Subtitle}</h3>}
        <Row className="row-grid align-items-center">
          <Col className="order-md-2" md="6">
            <ReactMarkdown
              className={
                item.RightContent?.includes("/uploads")
                  ? "img-fluid floating text-center"
                  : ""
              }
              transformImageUri={(uri) =>
                uri.startsWith("http")
                  ? uri
                  : `${process.env.REACT_APP_SERVER_URL?.replace(
                      "/api/",
                      "",
                    )}${uri}`
              }
            >
              {item.RightContent}
            </ReactMarkdown>
          </Col>
          <Col className="order-md-1" md="6">
            <div className="pr-md-5">
              <ReactMarkdown
                className={
                  item.LeftContent?.includes("/uploads")
                    ? "img-fluid floating text-center"
                    : ""
                }
                transformImageUri={(uri) =>
                  uri.startsWith("http")
                    ? uri
                    : `${process.env.REACT_APP_SERVER_URL?.replace(
                        "/api/",
                        "",
                      )}${uri}`
                }
              >
                {item.LeftContent}
              </ReactMarkdown>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default ContentSection;
