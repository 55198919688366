/* eslint-disable no-undef */
import React from "react";
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";

export default function Sidebar({ showSidebar, activeBundle }) {
  return (
    <div className="flex-xl-nowrap row">
      <div className="ct-sidebar col-12 col-md-3 col-xl-2" id="sidebar">
        {!activeBundle ? (
          <div className="w-100 h-25 d-flex align-items-center justify-content-center">
            <Spinner color="primary" />
          </div>
        ) : (
          <nav
            className={`collapse ct-links ${showSidebar ? "show" : ""}`}
            id="ct-docs-nav"
          >
            <div className="ct-toc-item active">
              <ul className="ct-sidenav nav">
                {activeBundle?.Sidebar.map((item) => {
                  return item.menu.Item.map((menuItem) => {
                    return (
                      <li
                        className={
                          window.location.pathname.includes(
                            menuItem.menu_item.URL,
                          )
                            ? "active"
                            : ""
                        }
                        key={menuItem.menu_item.id}
                      >
                        <Link
                          to={`/documentation${menuItem.menu_item.URL}?bundle=${activeBundle.id}`}
                        >
                          {menuItem.menu_item.Title}
                        </Link>
                      </li>
                    );
                  });
                })}
              </ul>
            </div>
          </nav>
        )}
      </div>
    </div>
  );
}
