import axios from "axios";
import { UnauthenticatedLayout } from "common/components/layouts/unauthenticated.layout";
import PasswordInput from "modules/components/password-input.component";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Row,
} from "reactstrap";

export const LoginPage: React.FC<any> = (_props: any) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);
  const [error, setError] = useState("");

  // eslint-disable-next-line no-undef
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setError("");
    event.preventDefault();
    if (!password || !email) {
      setError("All form fields are required");
      return;
    }
    const data = { identifier: email, password: password };
    try {
      // with the user data as the request body
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}auth/local`,
        data,
      );
      // eslint-disable-next-line no-undef
      localStorage.setItem("auth_token", response.data.jwt);
      // eslint-disable-next-line no-undef
      localStorage.setItem("auth_username", response.data.user.username);

      resetForm();
      // eslint-disable-next-line no-undef
      window.location.replace("/");
    } catch (error) {
      setError(error.response.data.error.message);
    }
  };

  const resetForm = () => {
    setError("");
    setEmail("");
    setPassword("");
  };

  return (
    <UnauthenticatedLayout>
      <section className="section section-shaped section-lg">
        <div className="shape shape-style-1 bg-gradient-default">
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
        </div>
        <Container className="pt-lg-7">
          <Row className="justify-content-center">
            <Col lg="7">
              <Card className="bg-secondary shadow border-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <h3>Sign in</h3>
                  </div>
                  {error && <p className="error">{error}</p>}
                  <Form role="form" onSubmit={handleSubmit}>
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>

                        <Input
                          placeholder="Email"
                          type="email"
                          id="email"
                          value={email}
                          onChange={(event) => setEmail(event.target.value)}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <PasswordInput
                        value={password}
                        onChange={(event: {
                          target: { value: React.SetStateAction<string> };
                        }) => setPassword(event.target.value)}
                      />
                    </FormGroup>
                    <div className="custom-control custom-control-alternative custom-checkbox">
                      <input
                        className="custom-control-input"
                        id=" customCheckLogin"
                        type="checkbox"
                        checked={remember}
                        onChange={() => {
                          setRemember(!remember);
                        }}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor=" customCheckLogin"
                      >
                        <span>Remember me</span>
                      </label>
                    </div>
                    <div className="text-center">
                      <Button className="my-4" color="primary" type="submit">
                        Sign in
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
              <Row className="mt-3">
                <Col xs="6">
                  <a
                    className="text-light"
                    href="/forgot-password"
                    // onClick={(e) => e.preventDefault()}
                  >
                    <small>Forgot password?</small>
                  </a>
                </Col>
                <Col className="text-right" xs="6">
                  <Link className="text-light" to="/register">
                    <small>Create new account</small>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </UnauthenticatedLayout>
  );
};
