/* eslint-disable no-undef */
import React from "react";
import { Redirect, Route } from "react-router-dom";

const PublicRoute = ({ children, ...rest }: any) => {
  const userToken = localStorage.getItem("auth_token");
  window.scrollTo(0, 0);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        !userToken ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
export default PublicRoute;
