import React from "react";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";

function HeroSection({ item }: any) {
  return (
    <>
      <section className="section section-lg section-shaped pb-250">
        <div
          className="shape shape-style-1 shape-default"
          style={{
            background:
              item.GradientColors &&
              `linear-gradient(150deg, ${item.GradientColors.Color1} 15%, ${item.GradientColors.Color2} 70%, ${item.GradientColors.Color3} 94%)`,
          }}
        >
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
        </div>
        <Container className="py-lg-md d-flex">
          <div className="col px-0">
            <Row>
              <Col lg="8">
                <h1 className="display-3 text-white">{item.Title} </h1>
                <p className="lead text-white">{item.Content}</p>
                {item?.CTAbutton.length > 0 && (
                  <div className="btn-wrapper">
                    {item?.CTAbutton.map((btn: any) => {
                      let btnIcon = "";

                      if (btn.Icon) {
                        btnIcon = `${process.env.REACT_APP_SERVER_URL?.replace(
                          "/api/",
                          "",
                        )}${btn.Icon.url}`;
                      }
                      return (
                        <Button
                          key={btn.id}
                          className="btn-icon mb-3 mb-sm-0 mx-1"
                          color="primary"
                          style={{
                            background: btn.Color,
                            borderColor: btn.Color,
                          }}
                          href={btn.url}
                        >
                          {btn.Icon && (
                            <span className="btn-inner--icon mr-1">
                              <img
                                src={btnIcon}
                                alt="btn-icon"
                                className="w-100 h-auto"
                                style={{
                                  filter: "brightness(0) invert(1)",
                                }}
                              />
                            </span>
                          )}
                          <span className="btn-inner--text">{btn.Label}</span>
                        </Button>
                      );
                    })}
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </Container>
        {/* SVG separator */}
        <div className="separator separator-bottom separator-skew">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon className="fill-white" points="2560 0 2560 100 0 100" />
          </svg>
        </div>
      </section>
      {item.Card.length > 0 && (
        <section className="section section-lg pt-lg-0 mt--200">
          <Container>
            <Row className="justify-content-center">
              <Col lg="12">
                <Row className="row-grid">
                  {item.Card?.map((card: any) => {
                    let cardIcon = "";

                    if (card.Icon) {
                      cardIcon = `${process.env.REACT_APP_SERVER_URL?.replace(
                        "/api/",
                        "",
                      )}${card.Icon.url}`;
                    }

                    return (
                      <Col lg={12 / item.Card?.length} key={card.id}>
                        <Card className="card-lift--hover shadow border-0">
                          <CardBody className="py-5">
                            {card.Icon && (
                              <div
                                className="icon icon-shape rounded-circle mb-4"
                                style={{ backgroundColor: card.MainColor }}
                              >
                                <img
                                  src={cardIcon}
                                  className="w-100 h-auto"
                                  alt="card-icon"
                                  style={{
                                    filter: "brightness(0) invert(1)",
                                  }}
                                />
                              </div>
                            )}
                            <h6
                              className={
                                !card.MainColor
                                  ? "text-primary text-uppercase"
                                  : "text-uppercase"
                              }
                              style={{
                                color: card.MainColor,
                              }}
                            >
                              {card.Title}
                            </h6>
                            <p className="description mt-3">{card.Content}</p>
                            <div>
                              {card.tag1 && (
                                <span
                                  className="mr-1 badge rounded-pill"
                                  style={{ background: card.MainColor }}
                                >
                                  {card.tag1}
                                </span>
                              )}
                              {card.tag2 && (
                                <span
                                  className="mr-1 badge rounded-pill"
                                  style={{ background: card.MainColor }}
                                >
                                  {card.tag2}
                                </span>
                              )}
                              {card.tag3 && (
                                <span
                                  className="mr-1 badge rounded-pill"
                                  style={{
                                    background: card.MainColor,
                                  }}
                                >
                                  {card.tag3}
                                </span>
                              )}
                            </div>
                            <Button
                              className="mt-4"
                              color="primary"
                              href={card.LinkToUrl}
                              style={{
                                background: card.MainColor,
                                borderColor: card.MainColor,
                              }}
                            >
                              {card.ButtonLabel}
                            </Button>
                          </CardBody>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      )}
    </>
  );
}

export default HeroSection;
