/* eslint-disable no-undef */
import axios from "axios";
import PublicLayout from "common/components/layouts/public.layout";
import PasswordInput from "modules/components/password-input.component";
import React, { useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
} from "reactstrap";

export const ChangePasswordPage: React.FC<any> = (_props: any) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [error, setError] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  // eslint-disable-next-line no-undef
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setError("");
    event.preventDefault();
    if (!password || !passwordConfirmation || !currentPassword) {
      setError("All form fields are required");
      return;
    }
    const headers = {
      "content-type": "application/json",
      // eslint-disable-next-line no-undef
      Authorization: "Bearer " + localStorage.getItem("auth_token"),
    };
    const data = {
      currentPassword: currentPassword,
      password: password,
      passwordConfirmation: passwordConfirmation,
    };
    try {
      // with the user data as the request body
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}auth/change-password`,
        data,
        {
          headers,
        },
      );

      if (response) {
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
          window.location.replace("/");
        }, 3000);
      }

      resetForm();
    } catch (err) {
      setError(err.response.data.error.message);
    }
  };

  const resetForm = () => {
    setError("");
    setCurrentPassword("");
    setPassword("");
    setPasswordConfirmation("");
  };

  return (
    <PublicLayout page={true}>
      <section className="section section-shaped section-lg">
        <div className="shape shape-style-1 bg-gradient-default">
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
        </div>
        <Container className="pt-lg-7">
          <Row className="justify-content-center">
            <Col lg="7">
              {showAlert && (
                <Alert color="success">
                  You have successfully changed your password.
                </Alert>
              )}
              <Card className="bg-secondary shadow border-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <h3>Change password</h3>
                  </div>
                  {error && <p className="error">{error}</p>}
                  <Form role="form" onSubmit={handleSubmit}>
                    <FormGroup>
                      <PasswordInput
                        placeholder="Current password"
                        value={currentPassword}
                        onChange={(event: {
                          target: { value: React.SetStateAction<string> };
                        }) => setCurrentPassword(event.target.value)}
                      />
                    </FormGroup>

                    <FormGroup>
                      <PasswordInput
                        placeholder="New Password"
                        value={password}
                        onChange={(event: {
                          target: { value: React.SetStateAction<string> };
                        }) => setPassword(event.target.value)}
                      />
                    </FormGroup>

                    <FormGroup>
                      <PasswordInput
                        placeholder="New password confirmation"
                        value={passwordConfirmation}
                        onChange={(event: {
                          target: { value: React.SetStateAction<string> };
                        }) => setPasswordConfirmation(event.target.value)}
                      />
                    </FormGroup>

                    <div className="text-center">
                      <Button className="my-4" color="primary" type="submit">
                        Submit new password
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </PublicLayout>
  );
};
