/* eslint-disable no-undef */
import axios from "axios";
import { ForgotPasswordPage } from "modules/forgot-password/forgot-password.page";
import { ResetPasswordPage } from "modules/forgot-password/reset-password.page";
import { GlobalPageLayout } from "modules/global-page-layout/global-page-layout.page";
import { LoginPage } from "modules/login/login.page";
import RegisterPage from "modules/register/register.page";
import React, { useEffect, useState } from "react";
import PublicRoute from "./PublicRoute";

const PublicRoutes = ({ ...rest }: any) => {
  const [routes, setRoutes] = useState<any>([]);

  const getPages = async () => {
    try {
      const response: any = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}pages`,
      );

      if (response.data) {
        setRoutes(response.data);

        const slugs = await Promise.all(
          response.data.map(async (item: { Slug: any }) => {
            return item.Slug;
          }),
        );

        const allRoute = [
          "login",
          "forgot-password",
          "reset-password",
          "register",
          ...slugs,
        ];

        if (!allRoute.includes(rest.location.pathname.replace("/", ""))) {
          window.location.replace("/login");
        } else {
          return;
        }
      }
    } catch (error) {
      // window.location.replace("/");
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getPages();
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }, []);

  const isRouteFromBackend = () => {
    const slugs = routes.map((item: any) => {
      return item.Slug;
    });

    if (slugs.includes(rest.location.pathname.replace("/", ""))) {
      return true;
    }
    return false;
  };

  return (
    <>
      <PublicRoute path="/login">
        <LoginPage />
      </PublicRoute>
      <PublicRoute path="/forgot-password">
        <ForgotPasswordPage />
      </PublicRoute>
      <PublicRoute path="/reset-password">
        <ResetPasswordPage />
      </PublicRoute>
      <PublicRoute path="/register">
        <RegisterPage />
      </PublicRoute>
      {isRouteFromBackend() && (
        <PublicRoute path={routes.Slug}>
          <GlobalPageLayout {...rest} />
        </PublicRoute>
      )}
    </>
  );
};
export default PublicRoutes;
